const initialState = {
    isShowProfile: false
}

export const setShowProfile = isShowProfile => ({
    type: "SHOW_PROFILE",isShowProfile
});

export default (state=initialState,action)=>{
    switch(action.type){
        case 'SHOW_PROFILE':
            return {...state,isShowProfile:action.isShowProfile}
        default:
            return state 
    }
}
